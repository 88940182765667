import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDrag } from 'react-dnd';
import GetAvatar from '../GetAvatars';
import { useCharacterStyle } from './Characters';
import '../dragDrop/style.css';

const Characters = ({
    character, bgColor, avatar, showDetails,
}) => {
    const classes = useCharacterStyle();
    const [{ opacity }, drag] = useDrag({
        type: 'sidebarItem',
        item: { id: character._id, character },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      });
    if (character !== undefined) {
        return (
            <Grid
                ref={drag}
                style={{ opacity }}
                className={classes.characterGrid}
                item
                xs={12}
                sm={12}
                md={12}
            >
                <Grid>
                    <Card
                        className={classes.card}
                        style={{ background: bgColor }}
                        onDoubleClick={showDetails}
                    >
                    <CardContent className={classes.cardContent}>
                      <div className={classes.details}>
                        <GetAvatar avatar={avatar} alt={character.type} />
                        <div className={classes.characterDiv}>
                          <Typography className={classes.characterName}>
                            {character.name}
                          </Typography>
                          <Typography className={classes.characterRole}>
                            The
                            {' '}
                            {character.masked_type ? character.masked_type : character.type}
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
            </Grid>
        );
    }
    return null;
};

Characters.propTypes = {
  character: PropTypes.object,
  bgColor: PropTypes.string,
  avatar: PropTypes.string,
  showDetails: PropTypes.func,
};

export default Characters;
