import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "@material-ui/core/MobileStepper";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RestartIcon from '../../../assets/images/icons/restart.png'
import CloseIcon from "@material-ui/icons/Close";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
// import LaunchIcon from '@material-ui/icons/Launch';
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import VideoStreamMerger from "video-stream-merger";
import RealTimeFeedbackDialog from './RealTimeFeedbackDialog.jsx';
import {
  fetchLevelClips,
  updateStats,
  addAudioToClip,
  reset,
  addScreenRecorderBlob,
  updateCurrentClip,
  restartLevelInTakeSim,
  uploadRecordedVideo,
  userFinalScoreResponse,
  addAssignmentStatus,
  saveVideoBlob,
  logError,
} from "../../../redux/sims/actions";
import Timer from "../Timer";
import {
  uploadAudio,
  getMissedCorrectKeywords,
  getAwsPresignedUrl,
  getUserScriptId,
} from "../../../services/result.service";
import GetAvatar from "../GetAvatars";
import {
  simWizard,
  simWizardActiveLevelButton,
  simWizardHideLevelButton,
  simWizardWithInternalData
} from "./SimWizard";
import {
  convertAudioToBinaryMessage,
  handleEventStreamMessage,
  resetEventStreamMessage,
  getAudioEventMessage,
} from "../../../utils/convertIntoBuffer";
import ScreenRecording from './ScreenRecording.jsx';
import MicrophoneStream from "microphone-stream";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactMic } from 'react-mic';
import { EventStreamCodec } from "@aws-sdk/eventstream-codec";

const CryptoJS = require("crypto-js");
//const marshaller = require("@aws-sdk/eventstream-marshaller");
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
//const eventStreamMarshaller = new marshaller.EventStreamMarshaller( // EventStreamMarshaller is deprecated
const eventStreamMarshaller = new EventStreamCodec(
  util_utf8_node.toUtf8,
  util_utf8_node.fromUtf8
);
const useQuery = () => new URLSearchParams(useLocation().search);

const SimWizard = ({
  fetchLevelClips,
  levelClips,
  levels,
  simId,
  updateStats,
  handleViewState,
  addAudioToClip,
  userResponse,
  reset,
  simTitle,
  addScreenRecorderBlob,
  updateCurrentClip,
  restartLevelInTakeSim,
  uploadRecordedVideo,
  caseValuesArray,
  elapsedTime,
  userFinalScoreResponse,
  addAssignmentStatus,
  logError,
  saveVideoBlob,
  captivateWindowRef,
  simProductType,
  config,
}) => {

  // import css by calling simWizard  function
  let useStyles;
  simProductType.includes('Internal Data') ?
    (useStyles = makeStyles((theme) => simWizardWithInternalData(theme))) :
    (useStyles = makeStyles((theme) => simWizard(theme)))
  const query = useQuery();
  const history = useHistory();
  const [level] = useState(query.get("levelId"));
  const [activeIndex] = useState(query.get("activeIndex"));
  let [continueSimLevel, setContinueSimLevel] = useState(
    query.get("continueSimLevel") === "true" ? true : false
  );
  const [activeClip, setActiveClip] = useState(
    activeIndex ? Number(activeIndex) : 0
  );
  const [record, setRecord] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const rec = useRef(null);
  const stream = useRef(null);
  const agentAudioPlayer = useRef(null);
  const audioPlayer = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const intervalRef = useRef(null);
  const [response, setResponse] = useState({});
  const [isCoach, setIsCoach] = useState(false);
  const [coachEnabled, setCoachEnabled] = useState(true);
  const [recPermDialog, setRecPerDialog] = useState(false);
  const [recPer, setRecPer] = useState(false);
  const [totalScreens, setTotalScreens] = useState("2");
  const [startAutoPlay, setStartAutoPlay] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState("");
  const screenRec = useRef(null);
  const screenStream = useRef(null);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disableDoneButton, setDisableDoneButton] = useState(false);
  const [start, setStart] = useState(false);
  const [presignedBool, setPresignedBool] = useState(false);
  const [timer, setTimer] = useState(0);
  const location = useLocation();
  let desktopStream;
  let desktopStream2;
  const trackElem = useRef(null);
  const trackElem2 = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [recordings, setRecordings] = React.useState([]);

  const recordRef = useRef(record);
  const isRecordingRef = useRef(isRecording);

  useEffect(() => {
    recordRef.current = record;
    isRecordingRef.current = isRecording;
  }, [record, isRecording]);

  let feedbackCounter = 0,
      maxFeedbackLoops = 500;

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (simProductType.includes('Salesforce')) {
      const initialSfUrl = 'http://cedarssinai--training.lightning.force.com/lightning';
      window.toSf({
        type: "newTab", // The type of message you are sending.
        url: initialSfUrl
      });
    }
  }, [simProductType]);
  useEffect(() => {
    fetchLevelClips(level);
    if (userResponse.mode) {
      updateStats(simId, level, "started", userResponse.mode, activeClip, true);
    }
  }, [level, levels, fetchLevelClips, simId, userResponse.mode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAwsPresignedUrl()
      .then((res) => {
        /* if (process.env.REACT_APP_STAGE != 'prod') {
          console.log('Use affect presigned URL getted');
        } */
        return setPresignedUrl(res.data.url);
      })
      .catch((err) => console.log(err));
    setTimer(0);
  }, [setPresignedUrl, presignedBool]);

  useEffect(() => {
    if (levelClips?.sim_scripts?.length) {
      for (const script of levelClips.sim_scripts) {
        if (
          script.character.type === "agent" &&
          script.real_time_feedback &&
          !script.correctKeywords &&
          !script.missedKeywords
        ) {
          script.correctKeywords = [];
          script.missedKeywords = [];
          script.recorded = false;
          script.audioTemp = script.audio_url;
        }
        if (script.character.type === "coach") {
          setIsCoach(true);
        }
        if (script.character.type === "coach") {
          setIsCoach(true);
        }
      }
    }
  }, [levelClips]);

  useEffect(() => {
    if (location.state.screenRecording === true && start === false) {
      setRecPerDialog(true);
      setStart(true);
    }
    if (location.state.screenRecording === false) {
      setStartAutoPlay(true);
    }
  }, [levelClips]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Retrieve the real-time grading of the recorded audio of the current SIM script (step)
   *
   * @param response
   * @param activeState
   */
  function fetchFeedback(response, activeState) {
    if (feedbackCounter++ > maxFeedbackLoops) {
      return;
    }

    if (
      !(levelClips.sim_scripts[activeState].missedKeywords && levelClips.sim_scripts[activeState].missedKeywords.length) ||
      !(levelClips.sim_scripts[activeState].correctKeywords && levelClips.sim_scripts[activeState].correctKeywords.length)
    ) {
      intervalRef.current = setTimeout(async () => {
        const res = await getMissedCorrectKeywords(response.data.id);
        if (
          (res.data.correct_keywords /*&& res.data.correct_keywords.length*/) ||
          (res.data.missed_keywords /*&& res.data.missed_keywords.length*/)
        ) {
          const newSimScripts = [];
          let updatedScript = {};
          for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
            if (i === activeState) {
              updatedScript = levelClips.sim_scripts[activeState];
              updatedScript.correctKeywords = res.data.correct_keywords;
              updatedScript.missedKeywords = res.data.missed_keywords;
              updatedScript.grade_score = res.data.grade_score;
              newSimScripts.push(updatedScript);
            } else {
              newSimScripts.push(levelClips.sim_scripts[i]);
            }
          }
          const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
          try {
            addAudioToClip(newLevelClip);
          } catch (error) {
            console.error('Caught error: ', error);
          }
          setLoader(false);
        } else {
          if (process.env.REACT_APP_STAGE !== 'prod') {
            console.log("Unknown getMissedCorrectKeywords response = %o", res);
          }
          setTimeout(() => {fetchFeedback(response, activeState);}, 1000);
        }
      }, 1000);
    }
  }

  const handleExitButton = async () => {
    if (recPer === true) {
      stopRecording();
    }

    setStart(false);
    setStartAutoPlay(false);
    setIsRecording(false);
    setOpen(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    if (Object.keys(captivateWindowRef).length !== 0 && captivateWindowRef.constructor === Object) {
      captivateWindowRef.close();
    }
    reset();
    // history.push(`/sims?exit=${true}&type=${location.state.type}`);
    history.push({
      pathname: '/sims',
      search: `?exit=${true}&type=${location.state.type}&assigned_to_id=${userResponse.user_id}`,
      state: {
        sim_id: simId,
        assigned_to_id: userResponse.user_id,
        ...(location.state.assignment.assignment_id) && { assignment_id: location.state.assignment.assignment_id },
      }
    })
  };

  const stopRecording = () => {
    if (screenStream.current === null) {
      return;
    }
    if (totalScreens === "2") {
      trackElem2.current.getTracks().forEach((track) => track.stop());
      trackElem2.current = null;
    }
    trackElem.current.getTracks().forEach((track) => track.stop());
    trackElem.current = null;
    screenRec.current.stop();
    screenRec.current = null;
    screenStream.current = null;
  };

  const handleDoneButton = async (simId, level, config) => {
    setStartAutoPlay(false);

    const sessionId = encryptData();
    const sfRequestData = { type: "completed", sessionId };

    window.toSf(sfRequestData);
    if (Object.keys(captivateWindowRef).length !== 0 && captivateWindowRef.constructor === Object) {
      captivateWindowRef.close();
    }

    levels.splice(Number(query.get("order")), 1);

    if (
      levelClips.sim_scripts[activeClip].real_time_feedback &&
      !levelClips.sim_scripts[activeClip].agent_recorded_audio &&
      levelClips.sim_scripts[activeClip].character.type === "agent"
    ) {
      if (
        levelClips.sim_real_time_feedback &&
        levelClips.sim_scripts[activeClip].audio_url &&
        levelClips.sim_scripts[activeClip].recorded
      ) {
        setOpen(true);
      } else {
        updateStats(
          simId,
          level,
          "completed",
          userResponse.mode,
          activeClip,
          false
        );
        handleViewState(true);
        reset();
      }
      if ((levelClips.sim_scripts[activeClip].keywords.length > 0) || (levelClips.sim_scripts[activeClip].tests.length > 0) ) {
        setLoader(true);
        fetchFeedback(response, activeClip);
      }
    } else {
      updateStats(
        simId,
        level,
        "completed",
        userResponse.mode,
        activeClip,
        false
      );
      handleViewState(true);
      reset();
      if (recPer === true) {
        stopRecording();
      }
    }
  };

  // save transcribe text to DB
  const saveTranscribeToDB = (
    userResponseId,
    scriptId,
    blob,
    transcribe_text
  ) => {
    const formData = new FormData();
    formData.append("sim_level_user_response", userResponseId);
    formData.append("script_id", scriptId);
    formData.append("audio_blob", blob);
    formData.append("transcribed_text", transcribe_text);
    if (process.env.REACT_APP_STAGE !== 'prod') {
      console.log(transcribe_text);
    }
    return uploadAudio(formData);
  };

  const isUrlExpired = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    const amzDate = urlParams.get('X-Amz-Date');

    if (!amzDate) return true;

    const expirationTime = new Date(
      `${amzDate.slice(0, 4)}-${amzDate.slice(4, 6)}-${amzDate.slice(6, 8)}T${amzDate.slice(9, 11)}:${amzDate.slice(11, 13)}:${amzDate.slice(13, 15)}Z`
    );

    expirationTime.setMinutes(expirationTime.getMinutes() + 4);
    /* if (process.env.REACT_APP_STAGE != 'prod') {
      console.log('Expired at' + expirationTime);
    } */
    return new Date() > expirationTime;
  };

  // Function for recording audio in auto play mode
  const handleAutoPlayRecordStart = async (active, loading) => {
    setRecord(true);
    setIsRecording(true);
    let time = timer
    let transcription = "",
      partial = "",
      isFinal;
    if (isPlaying) {
      if (agentAudioPlayer.current)
        agentAudioPlayer.current.pause();
      setIsPlaying(false);
    }
    let activeState = active;
    if (activeState === levelClips.sim_scripts.length - 1)
      setDisableDoneButton(true);
    // if (levelClips.sim_scripts[activeState].real_time_feedback && levelClips.sim_real_time_feedback)
    setDisableNextButton(true);
    activeState = activeState === undefined ? 0 : activeState;
    let voiceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    let micStream = new MicrophoneStream(); // ws
    stream.current = new MediaStream(voiceStream);

    let blobs = [];
    micStream.setStream(voiceStream); // ws

    let interval = setInterval(() => {
      time = time + 1
    }, 1000)
    let newTemporalUrl = null;
    if (isUrlExpired(presignedUrl)) {
      await getAwsPresignedUrl()
        .then((res) => {
          /* if (process.env.REACT_APP_STAGE != 'prod') {
            console.log('New presigned URL getted');
          } */
          newTemporalUrl = res.data.url
          return setPresignedUrl(res.data.url);
        })
        .catch((err) => console.log(err));
    }
    let preUrl;
    if (newTemporalUrl) {
      /* if (process.env.REACT_APP_STAGE != 'prod') {
        console.log('Temp URL '+newTemporalUrl);
      } */
      preUrl = newTemporalUrl;
    } else {
      /* if (process.env.REACT_APP_STAGE != 'prod') {
        console.log('URL '+presignedUrl);
      } */
      preUrl = presignedUrl;
    }
    let ws = new WebSocket(preUrl); // ws  
    ws.binaryType = "arraybuffer"; // ws

    ws.onopen = function (event) {
      micStream.on("data", (e) => {
        const buffer = convertAudioToBinaryMessage(e);
        if (ws.readyState === 1 && recordRef.current) {
          ws.send(buffer);
          resetEventStreamMessage();
        }
      });
    };

    ws.addEventListener("message", function (message) {
      let messageWrapper = eventStreamMarshaller.decode(//unmarshall(
        Buffer(message.data)
      );
      let messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      );

      switch (messageWrapper.headers[":message-type"].value) {
        case 'event':
          //console.log('Message Body = %o', messageBody);
          [transcription, partial, isFinal] = handleEventStreamMessage(messageBody, transcription);
          //console.log('is record %b', recordRef.current);
          //console.log('is final %b', isFinal);
          if (!recordRef.current) {
            setTimeout(() => {
              if (ws.readyState === 1) {
                //console.log('starting close');
                ws.close();
              }
            }, 700);
          }
          /* console.log('Partial = %s', partial);
          console.log('transcription = %s', transcription); */
          
        break;

        case 'exception':
          console.error('WS Error = %o', messageBody);
          alert('There was a problem during this recording please retry');
          logError({'logError':'Error on the frontend cause by web socket: '+ messageBody.Message});
          handleRetryOnFailure();
        break;

        default:
          //console.log('MessageWrapper: %o', messageWrapper);
      }
    });

    /*
    rec.current = new MediaRecorder(stream.current, {
      mimeType: "audio/webm",
    });
    rec.current.ondataavailable = (e) => blobs.push(e.data);
    */
    let mediaRec = new MediaRecorder(stream.current, { mimeType: "audio/webm" });
    mediaRec.ondataavailable = (e) => blobs.push(e.data);

    mediaRec.onstop = async () => {
      if (loading) setLoader(true);
      micStream.stop(); // ws
      // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
      let emptyMessage = getAudioEventMessage(Buffer.alloc(0));
      let emptyBuffer = eventStreamMarshaller.encode(emptyMessage);//marshall(emptyMessage);
      if (ws.readyState === 1) {
        ws.send(emptyBuffer);
      }
      /*
      let ws2 = new WebSocket(presignedUrl); //ws
      ws2.binaryType = "arraybuffer"; //ws

      ws2.addEventListener('open', (ev) => {
        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.encode(emptyMessage);//marshall(emptyMessage);
        ws.send(emptyBuffer);
        ws2.close();
      });
      */

      // blobs.push(MediaRecorder.requestData());
      let blob = new Blob(blobs, { type: "audio/mp3" });
      const url = window.URL.createObjectURL(blob);
      // create new simScripts with this audio url added
      const newSimScripts = [];
      let updatedScript = {};

      for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
        if (i === activeState) {
          updatedScript = levelClips.sim_scripts[activeState];
          updatedScript.audio_url = url;
          setContinueSimLevel(false);
          if (userResponse.mode === "practice") {
            updatedScript.audioTemp = url;
            updatedScript.recorded = true;
          }
          newSimScripts.push(updatedScript);
        } else {
          newSimScripts.push(levelClips.sim_scripts[i]);
        }
      }

      const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
      addAudioToClip(newLevelClip);

      let result = {};
      ws.onclose = async function (event) {
        clearInterval(interval);
        setDisableNextButton(false);
        setTimer(time);
        if (time > 10) {
          setPresignedBool(!presignedBool)
        }

        // Call Lambda grading function here.
        // Change below to save grade and transcription to the db instead of
        // calculating it in the back end
        //console.log('Partial = %s', partial);
        //console.log('transcription = %s', transcription);
        result = await saveTranscribeToDB(
          userResponse._id,
          levelClips.sim_scripts[activeState]._id,
          blob,
            transcription.length ? transcription : partial
        );

        setResponse(response);
        setRecordings([...recordings, result.data.id]);

        if (activeState === levelClips.sim_scripts.length - 1)
          setDisableDoneButton(false);

        levelClips.sim_scripts[activeState].transcription = transcription.length ? transcription : partial;
        if (
          //  userResponse.mode === "autoplay" &&
          levelClips.sim_scripts[activeState].character.type === "agent" &&
          levelClips.sim_scripts[activeState].real_time_feedback &&
          levelClips.sim_real_time_feedback &&
          (levelClips.sim_scripts[activeState].keywords.length > 0 || levelClips.sim_scripts[activeState].natural_lenguage) //&&
          //!levelClips.sim_scripts[activeState].agent_recorded_audio
        ) {
          setOpen(true);
          fetchFeedback(result, activeState);
        } else {
          if (loading) setLoader(true);
        }
      };
    };

    rec.current = mediaRec;
    rec.current.start();
  };

  const handleChange = async () => {
    const newSimScripts = [];
    let updatedScript = {};
    for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
      if (i === activeClip) {
        updatedScript = levelClips.sim_scripts[activeClip];
        if (updatedScript.audio_url === "" && updatedScript.audioTemp !== "") {
          updatedScript.audio_url = updatedScript.audioTemp;
          setContinueSimLevel(false);
        }
        updatedScript.correctKeywords = [];
        updatedScript.missedKeywords = [];
        updatedScript.recorded = false;
        newSimScripts.push(updatedScript);
      } else {
        newSimScripts.push(levelClips.sim_scripts[i]);
      }
    }
    const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
    addAudioToClip(newLevelClip);
  };

  const handleRetryOnFailure = () => {
    const newSimScripts = [];
    let updatedScript = {};
    for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
      if (i === activeClip) {
        updatedScript = levelClips.sim_scripts[activeClip];
        updatedScript.correctKeywords = [];
        updatedScript.missedKeywords = [];
        updatedScript.audio_url = '';
        updatedScript.agent_recorded_audio = '';
        updatedScript.recorded = false;
        newSimScripts.push(updatedScript);
      } else {
        newSimScripts.push(levelClips.sim_scripts[i]);
      }
    }
    const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
    addAudioToClip(newLevelClip);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setRecord(false);
    if (rec && rec.current)
      rec.current.stop();
    stream.current = null;
    setIsRecording(false);
    if (isUrlExpired(presignedUrl)) {
      getAwsPresignedUrl()
        .then((res) => {
          /* if (process.env.REACT_APP_STAGE != 'prod') {
            console.log('New presigned URL getted from error');
          } */
          return setPresignedUrl(res.data.url);
        })
        .catch((err) => console.log(err));
    }
    
    //setOpen(false);
    if (userResponse.mode === 'autoplay' && levelClips.sim_scripts[activeClip].character.type === "agent") {
      handleAutoPlayRecordStart(activeClip, true);
    }
  };

/*
  const simulateAudioUpload = async () => {
    let result = {};
    let activeState = activeClip;
    result = await saveTranscribeToDB(
      userResponse._id,
      levelClips.sim_scripts[activeState]._id,
      new Blob([], { type: "audio/mp3" }),
      'Hi this is a test text'
    );
    setResponse(result);
    fetchFeedback(result, activeState);
    setOpen(true);
  };
*/

  const handleBackChange = async () => {
    updateStats(
      simId,
      level,
      "started",
      userResponse.mode,
      activeClip - 1,
      true,
      true
    );
    if (activeClip > 0) {
      if (
        !levelClips.sim_scripts[activeClip].audio_url &&
        levelClips.sim_scripts[activeClip].audioTemp
      ) {
        handleChange();
      }
      setActiveClip(activeClip - 1);
    }
  };

  // Get the session Id by encrypting userResponse Id and sim Id
  const encryptData = () => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(`${userResponse._id}-${simId}`), 'rlc-secret-key@123').toString();
    //console.log('Encrypt Data -->', cipherText, userResponse._id);
    return cipherText;
  };

  const handleNextChange = async () => {
    if (activeClip === 0) {
      const sessionId = encryptData();
      const sfRequestData = { type: "sessionId", sessionId };
      window.toSf(sfRequestData);
      //console.log('SF Session Started');
    }
    setOpen(false);
    if (activeClip === levelClips.sim_scripts.length - 1) {
      updateStats(
        simId,
        level,
        "started",
        userResponse.mode,
        activeClip,
        true,
        true
      );
    } else {
      updateStats(
        simId,
        level,
        "started",
        userResponse.mode,
        activeClip + 1,
        true,
        true
      );
      if (
        userResponse.mode === "autoplay" &&
        levelClips.sim_scripts[activeClip + 1].character.type === "agent" &&
        !levelClips.sim_scripts[activeClip + 1].agent_recorded_audio
      ) {
        setActiveClip(activeClip + 1);
        handleAutoPlayRecordStart(activeClip + 1, true);
      }
      if (
        levelClips.sim_scripts[activeClip].real_time_feedback &&
        levelClips.sim_real_time_feedback &&
        !levelClips.sim_scripts[activeClip].agent_recorded_audio &&
        levelClips.sim_scripts[activeClip].character.type === "agent"
      ) {
        if (
          continueSimLevel &&
          levelClips.sim_scripts[activeClip].agent_recorded_url
        ) {
          levelClips.sim_scripts[activeClip].recorded = true;
        }
        if (
          levelClips.sim_scripts[activeClip].audio_url &&
          levelClips.sim_scripts[activeClip].recorded
        ) {
          setOpen(true);
          const userScriptId = await getUserScriptId(
            userResponse._id,
            levelClips.sim_scripts[activeClip]._id
          );
          let result;
          if (
            userScriptId &&
            userScriptId.data &&
            userScriptId.data.user_script_id
          )
            result = { data: { id: userScriptId?.data.user_script_id } };
          if ((levelClips.sim_scripts[activeClip].keywords.length > 0) || (levelClips.sim_scripts[activeClip].tests.length > 0)) {
            setLoader(true);
            fetchFeedback(result ? result : response, activeClip);
          }
          handleChange();
        } else if (
          !levelClips.sim_scripts[activeClip].audio_url &&
          levelClips.sim_scripts[activeClip].audioTemp
        ) {
          handleChange();
          setActiveClip(activeClip + 1);
        } else {
          setActiveClip(activeClip + 1);
        }
      } else {
        setActiveClip(activeClip + 1);
      }
    }
    if (isPlaying) {
      if (agentAudioPlayer.current)
        agentAudioPlayer.current.pause();
      setIsPlaying(false);
    }
  };

  const handleRecordStop = () => {
    setRecord(false);
    if (rec && rec.current)
      rec.current.stop();
    stream.current = null;
    setIsRecording(false);
    if (
      //userResponse.mode === "autoplay" &&
      levelClips.sim_scripts[activeClip].character.type === "agent" &&
      levelClips.sim_scripts[activeClip].real_time_feedback &&
      levelClips.sim_real_time_feedback &&
      !levelClips.sim_scripts[activeClip].agent_recorded_audio
    ) {
      setOpen(true);
    } else if (userResponse.mode === "autoplay") {
      handleNextChange();
    }
    updateCurrentClip(levelClips.sim_scripts[activeClip]);

  };
  // when audio already recorded handle play button
  const playRecordedAudio = () => {
    agentAudioPlayer.current.play();
    setIsPlaying(true);
    //  when audio playback ends, set isPlaying to false
    agentAudioPlayer.current.onended = () => {
      setIsPlaying(false);
    };
  };
  // when audio already recorded handle pause button
  const pauseRecordedAudio = () => {
    if (agentAudioPlayer.current)
      agentAudioPlayer.current.pause();
    setIsPlaying(false);
  };
  // play script audio (other than agent)
  const playAudio = () => {
    if (audioPlayer && audioPlayer.current)
      audioPlayer.current.play();
    setIsAudioPlaying(true);
    //  when audio playback ends, set isPlaying to false
    audioPlayer.current.onended = () => {
      setIsAudioPlaying(false);
      if (userResponse && userResponse.mode === "autoplay") {
        handleNextChange();
      }
      updateCurrentClip(levelClips.sim_scripts[activeClip]);
    };
  };

  const audioChange = () => {
    playAudio();
  };
  const pauseAudio = () => {
    if (audioPlayer && audioPlayer.current)
      audioPlayer.current.pause();
    setIsAudioPlaying(false);
  };
  const classes = useStyles();
  const showPlayButton = (audioPlay, script) => {
    if (audioPlay && script.audio_url) {
      return (
        <IconButton
          color="primary"
          aria-label="pause"
          component="span"
          size="small"
          className={
            activeClip === levelClips.sim_scripts.length - 1 &&
              userResponse.mode === "autoplay"
              ? classes.pauseAutoplayButton
              : classes.pauseButton
          }
          onClick={() => pauseAudio()}
        >
          <PauseIcon />
        </IconButton>
      );
    }
    if (script.audio_url) {
      return (
        <IconButton
          color="primary"
          aria-label="pause"
          component="span"
          size="small"
          onClick={() => playAudio()}
          className={
            activeClip === levelClips.sim_scripts.length - 1 &&
              userResponse.mode === "autoplay"
              ? classes.pauseAutoplayButton
              : classes.pauseButton
          }
        >
          <PlayIcon />
        </IconButton>
      );
    }
    if (
      userResponse.mode === "practice" ||
      (userResponse.mode === "autoplay" &&
        activeClip === levelClips.sim_scripts.length - 1)
    ) {
      return (
        <IconButton
          color="primary"
          aria-label="no audio"
          component="span"
          size="small"
          className={
            activeClip === levelClips.sim_scripts.length - 1 &&
              userResponse.mode === "autoplay"
              ? classes.pauseAutoplayButton
              : classes.pauseButton
          }
          onFocus={handleNextChange}
        >
          <PriorityHighRoundedIcon />
        </IconButton>
      );
    }
    handleNextChange();
  };

  const showScript = (hideText, hintText, script) => {
    let hint_text = false;
    levels.map((lvl, idx) => {
      if (lvl.level_id === level) {
        hint_text = lvl.hint_text;
      }
      return idx;
    })
    if ((hideText && hintText) || (!script && hintText)) {
      return (
        <Typography
          variant="subtitle1"
          align="justify"
          className={classes.scriptTag}
        >
          {hint_text ?
            <em className={classes.hintText}>
              {levelClips.sim_scripts[activeClip].hint_text}
            </em> : ''
          }
        </Typography>
      );
    }
    if (script && hintText) {
      return (
        <Typography
          variant="subtitle1"
          align="justify"
          className={classes.scriptTag}
        >
          {levelClips.sim_scripts[activeClip].script}
          <br />
          {
            hint_text ?
              <em className={classes.hintText}>
                {levelClips.sim_scripts[activeClip].hint_text}
              </em> : ''
          }
        </Typography>
      );
    }
    if (hideText) {
      return (
        <Typography
          variant="subtitle1"
          align="justify"
          className={classes.scriptTag}
        >
          {hint_text ?
            <em className={classes.hintText}>
              {levelClips.sim_scripts[activeClip].hint_text}
            </em> : ''
          }

        </Typography>
      );
    }
    return (
      <Typography
        variant="subtitle1"
        align="justify"
        className={classes.scriptTag}
      >
        {levelClips.sim_scripts[activeClip].script}
      </Typography>
    );
  };

  const handleRestart = async () => {
    setRecord(false);
    if (rec && rec.current)
      rec.current.stop();
    if (stream && stream.current)
      stream.current = null;
    restartLevelInTakeSim();
    setCoachEnabled(true);
    setIsRecording(false);
    setOpen(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    fetchLevelClips(level, recordings);
    setActiveClip(0);
    if (Object.keys(captivateWindowRef).length !== 0 && captivateWindowRef.constructor === Object) {
      captivateWindowRef.close();
    }
    const payload = {
      ...caseValuesArray,
      isPost: true,
      assignment_id: userResponse.assignment_id,
      elapsedTime: elapsedTime,
      exit_or_restart: true,
    };
    userFinalScoreResponse(userResponse._id, payload);
    setRecordings([]);
  };

  const handleRestartWithoutCoach = () => {
    restartLevelInTakeSim();
    setCoachEnabled(false);
    setIsRecording(false);
    setOpen(false);
    if (Object.keys(captivateWindowRef).length !== 0 && captivateWindowRef.constructor === Object) {
      captivateWindowRef.close();
    }
    const clips = levelClips.sim_scripts.filter(
      (script) => script.character.type !== "coach"
    );
    const newSimScripts = [];
    let updatedScript = {};
    for (let i = 0; i < clips.length; i += 1) {
      if (
        clips[i].character.type === "agent" &&
        !clips[i].agent_recorded_audio
      ) {
        updatedScript = clips[i];
        updatedScript.audio_url = "";
        updatedScript.agent_recorded_audio = "";
        newSimScripts.push(updatedScript);
      } else {
        newSimScripts.push(clips[i]);
      }
    }
    const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
    addAudioToClip(newLevelClip);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setActiveClip(0);
  };
  // Toggling coach
  const showCoach = () => {
    if (coachEnabled) {
      return (
        <div className={classes.characterDiv}>
          <Typography
            className={classes.characterTypo}
            onClick={handleRestartWithoutCoach}
          >
            Coach : On
            <span className={classes.characterSpan}>
              &nbsp; Restart Without Coach
            </span>
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.characterDiv}>
        <Typography className={classes.characterTypo} onClick={handleRestart}>
          Coach : Off
          <span className={classes.characterSpan}>
            &nbsp; Restart With Coach
          </span>
        </Typography>
      </div>
    );
  };
  // Handling enable/disable real time feedback
  const handleRadioChange = (event) => {
    const newSimScripts = [];
    let updatedScript = {};
    for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
      if (levelClips.sim_scripts[i].character.type === "agent") {
        updatedScript = levelClips.sim_scripts[i];
        updatedScript.real_time_feedback = event.target.value === "yes";
        newSimScripts.push(updatedScript);
      } else {
        newSimScripts.push(levelClips.sim_scripts[i]);
      }
    }
    const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
    addAudioToClip(newLevelClip);
  };

  const dialogClose = () => {
    setStartAutoPlay(true);
    setRecPerDialog(false);
    setRecPer(false);
    setTotalScreens("2");
  };

  const mergeAudioStreams = (
    desktopStream,
    screenVoiceStream,
    desktopStream2
  ) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (screenVoiceStream && screenVoiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(screenVoiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    if (
      totalScreens === "2" &&
      desktopStream2 &&
      desktopStream2.getAudioTracks().length > 0
    ) {
      const source3 = context.createMediaStreamSource(desktopStream2);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source3.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };
  // merge streams from 2 screens
  const mergeVideos = (merger, desktopStream, desktopStream2, mute) => {
    if (totalScreens === "1") {
      merger.addStream(desktopStream, {
        x: 800, // position of the topleft corner
        y: 260,
        width: 2200,
        height: 1485,
        mute,
      });
    }

    // Add the screen 2
    if (totalScreens === "2") {
      // Add the screen 1
      merger.addStream(desktopStream, {
        x: 0, // position of the topleft corner
        y: 350,
        width: 1920,
        height: 1285,
        mute,
      });

      merger.addStream(desktopStream2, {
        x: 1940,
        y: 350,
        width: 1920,
        height: 1285,
        mute,
      });
    }
    merger.setOutputSize(3840, 2160);
    merger.start();
    return merger.result;
  };

  const startRecording = () => {
    screenRec.current.start();
  };

  const screenRecording = async () => {
    let screenVoiceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    let tracks = [];
    const merger = new VideoStreamMerger();
    const audioTrack = desktopStream.getAudioTracks();
    if (totalScreens === "1") {
      if (audioTrack.length > 0) {
        tracks = [
          ...mergeVideos(merger, desktopStream, undefined, false).getVideoTracks(),
          ...mergeAudioStreams(desktopStream, screenVoiceStream),
        ];
      } else {
        tracks = [
          ...mergeVideos(merger, desktopStream, undefined, true).getVideoTracks(),
        ];
      }
    } else {
      const audioTrack2 = desktopStream2.getAudioTracks();
      if (audioTrack.length <= 0 && audioTrack2.length <= 0) {
        tracks = [
          ...mergeVideos(merger, desktopStream, desktopStream2, true).getVideoTracks(),
        ];
      } else {
        tracks = [
          ...mergeVideos(merger, desktopStream, desktopStream2, true).getVideoTracks(),
          ...mergeAudioStreams(
            desktopStream,
            screenVoiceStream,
            desktopStream2
          ),
        ];
      }
    }
    screenStream.current = new MediaStream(tracks);
    setStartAutoPlay(true);
    let screenBlobs = [];
    screenRec.current = new MediaRecorder(screenStream.current, {
      mimeType: "video/webm;codecs=vp9",
    });
    screenRec.current.ondataavailable = (e) => screenBlobs.push(e.data);
    screenRec.current.onstop = async () => {
      let screenBlob = new Blob(screenBlobs, { type: "video/webm" });
      const formData = new FormData();
      formData.append("userResponseId", userResponse._id);
      formData.append("video_blob", screenBlob);
      // uploadRecordedVideo(formData);
      window.URL.createObjectURL(screenBlob);
      addScreenRecorderBlob(screenBlob);
      saveVideoBlob(formData);
      merger.destroy()
    };
    startRecording();
  };

  const capture = async () => {
    // download.current.style.display = 'none';
    navigator.mediaDevices
      .getDisplayMedia({
        video: { cursor: "always" },
        audio: true,
      })
      .then((res) => {
        desktopStream = res;
        trackElem.current = desktopStream;
        if (totalScreens === "2") {
          navigator.mediaDevices
            .getDisplayMedia({
              video: { cursor: "always" },
              audio: true,
            })
            .then((res1) => {
              desktopStream2 = res1;
              trackElem2.current = desktopStream2;
              screenRecording();
            })
            .catch((err) => {
              if (err.name === "NotAllowedError") {
                setStartAutoPlay(true);
              }
            });
        } else {
          screenRecording();
        }
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          setStartAutoPlay(true);
        }
      });
  };

  if ("getDisplayMedia" in navigator.mediaDevices === false) {
    return (
      <p>Enable chrome://flags/#enable-experimental-web-platform-features</p>
    );
  }

  if (
    levelClips &&
    levelClips.sim_scripts &&
    levelClips.sim_scripts.length > 0 &&
    levelClips.sim_scripts[activeClip].character !== null
  ) {
    if (
      levelClips.sim_scripts[activeClip].character.type === "agent" &&
      !levelClips.sim_scripts[activeClip].audio_url
    ) {
      if (
        userResponse.mode === "autoplay" &&
        activeClip === 0 &&
        !isRecording &&
        !open &&
        startAutoPlay
      ) {
        handleAutoPlayRecordStart(activeClip, false);
      }
    }

    const changeClip = (event) => {
      setAnchorEl(event.currentTarget);
    }
    // Method to n'th Clip
    const jumpToNext = (index) => {
      setAnchorEl(null);
      setActiveClip(index);
      updateStats(
        simId,
        level,
        "started",
        userResponse.mode,
        index,
        true,
        true
      );
    }
    return (
      <div>
      <Paper className={classes.paper}>
        <Timer />
        <ScreenRecording
          capture={capture}
          setRecPerDialog={setRecPerDialog}
          dialogClose={dialogClose}
          recPermDialog={recPermDialog}
          recPer={recPer}
          setRecPer={setRecPer}
          totalScreens={totalScreens}
          setTotalScreens={setTotalScreens}
          classes={classes}
        />
        <div className={classes.mainDiv}>
          <Grid container>
            <Grid xs={6} item>
              <div className={classes.levelDiv}>
                <span className={classes.lvlName}>Level {Number(query.get("order")) + 1} : </span><span>{levelClips.name}</span>
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={classes.root}>
                {levels.map((l, index) => (
                  <div key={l.level_id}>
                    <Button
                      key={l.level_id}
                      variant="contained"
                      disableElevation
                      className={classes.levelButton}
                      style={l.level_id === level
                        ? simWizardActiveLevelButton
                        : simWizardHideLevelButton}
                      endIcon={userResponse && userResponse.mode === 'practice' ? <KeyboardArrowDownIcon /> : ''}
                      onClick={userResponse && userResponse.mode === 'practice' ? changeClip : ''}
                    >
                      <span className={classes.activeClip}>{activeClip + 1} </span>
                      <span className={classes.totalClip}>/ {levelClips.sim_scripts.length}</span>
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      className={classes.menu}
                    >
                      {levelClips.sim_scripts.map((script, index) => (
                        <MenuItem
                          key={script._id} 
                          selected={index === activeClip} 
                          className={classes.menuItem}
                          onClick={() => jumpToNext(index)}
                        >
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>

          <div className={levelClips.sim_scripts.length < 10 ? classes.mobileRoot : classes.mobileStepper}>
            <MobileStepper
              variant={levelClips.sim_scripts.length < 10 ? "dots" : "progress"}
              steps={
                levelClips.sim_scripts && levelClips.sim_scripts.length > 0
                  ? levelClips.sim_scripts.length
                  : 1
              }
              className={classes.mobileProgress}
              activeStep={activeClip}
              position="static"
              classes={{
                dots: classes.dots,
                root: classes.stepperRoot,
                dot: classes.dot,
                dotActive: classes.dotActive,
              }}
            />
          </div>
          <div>
            <Grid container spacing={2} className={classes.mainInfoDiv}>
              <Grid item xs={12}>
                <div className={classes.avatarDiv}>
                  <GetAvatar
                    avatar={levelClips.sim_scripts[activeClip].character.avatar}
                    classes={classes.avatar}
                    alt="Img"
                  />
                </div>
                <div className={classes.characterInfo}>
                  <Button
                    variant="outlined"
                    disabled
                    className={classes.characterName}
                  >
                    {levelClips.sim_scripts[activeClip].character.name} -{"The  "}
                    {levelClips.sim_scripts[activeClip].character.masked_type
                      ? levelClips.sim_scripts[activeClip].character.masked_type
                      : levelClips.sim_scripts[activeClip].character.type}
                  </Button>
                </div>
                {isCoach ? showCoach() : null}
                {levelClips.sim_real_time_feedback && levelClips.level_real_time_feedback &&
                  levelClips.sim_scripts[activeClip].character.type === "agent" ? (
                  <div className={classes.radioDiv}>
                    <FormControl className={classes.radioBtnDiv} component="fieldset">
                      <FormLabel className={classes.radioTypo} component="legend">
                        Real-time feedback?:
                      </FormLabel>
                      <RadioGroup
                        defaultValue="yes"
                        aria-label="feedback"
                        className={classes.radioBtnDiv}
                      >
                        <FormControlLabel
                          className={classes.radioBtn}
                          value="yes"
                          control={
                            <Radio
                              checked={
                                levelClips.sim_scripts[activeClip].real_time_feedback
                              }
                              value="yes"
                              onChange={(e) => handleRadioChange(e)}
                              className={classes.radioYes}
                              size="small"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          className={classes.radioBtn}
                          value="no"
                          control={
                            <Radio
                              checked={
                                !levelClips.sim_scripts[activeClip].real_time_feedback
                              }
                              value="no"
                              onChange={(e) => handleRadioChange(e)}
                              className={classes.radioNo}
                              size="small"
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} className={classes.scriptGrid}>
                {showScript(
                  levelClips.sim_scripts[activeClip].hide_text,
                  levelClips.sim_scripts[activeClip].hint_text,
                  levelClips.sim_scripts[activeClip].script
                )}
              </Grid>
            </Grid>
          </div>
          {/* {activeClip === 0 ?
            <div className={classes.sfDiv}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={openSf}
                endIcon={<LaunchIcon />}
                size="small"
              >
                Open SalesForce
              </Button>
            </div> : null} */}
          <div
            className={
              userResponse.mode === "practice"
                ? classes.nextBackDiv
                : classes.nextBackAutoplayDiv
            }
          >
            {userResponse && userResponse.mode === "practice" && !isRecording && (
              <Button
                variant="contained"
                disableElevation
                className={
                  activeClip === 0
                    ? classes.backButton
                    : classes.activeBackButton
                }
                //startIcon={<ArrowBackIcon />}
                onClick={handleBackChange}
              >
                <ArrowBackIosIcon className={classes.arrowBack} />
              </Button>
            )}
            {startAutoPlay === true && (
              <div>
                {levelClips.sim_scripts[activeClip].character.type ===
                  "agent" &&
                  !levelClips.sim_scripts[activeClip].agent_recorded_audio ? (
                  <audio
                    key={levelClips.sim_scripts[activeClip].audio_url}
                    ref={audioPlayer}
                  >
                    <source
                      src={levelClips.sim_scripts[activeClip].audio_url}
                      type="audio/mp3"
                    />
                  </audio>
                ) : (
                  <audio
                    key={levelClips.sim_scripts[activeClip].audio_url}
                    ref={audioPlayer}
                    onLoadedData={audioChange}
                  >
                    <source
                      src={levelClips.sim_scripts[activeClip].audio_url}
                      type="audio/mp3"
                    />
                  </audio>
                )}
              </div>
            )}

            {/* if character type is agent , record audio */}
            {levelClips.sim_scripts[activeClip].character.type === "agent" &&
              !levelClips.sim_scripts[activeClip].agent_recorded_audio ? (
              isRecording && startAutoPlay === true ? (
                <IconButton
                  color="primary"
                  aria-label="stop mic recording"
                  component="span"
                  size="medium"
                  className={classes.recButton}
                  onClick={handleRecordStop}
                >
                  <MicOffIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="start mic recording"
                  component="span"
                  size="medium"
                  className={classes.pauseButton}
                  onClick={() => handleAutoPlayRecordStart(activeClip, false)}
                  disableFocusRipple={false}
                >
                  <MicIcon />
                </IconButton>
              )
            ) : (
              showPlayButton(isAudioPlaying, levelClips.sim_scripts[activeClip])
            )}

            {activeClip === levelClips.sim_scripts.length - 1 &&
              !isRecording ? (
              <Button
                variant="contained"
                disableElevation
                className={(isAudioPlaying && levelClips && levelClips.sim_scripts && levelClips.sim_scripts[activeClip])
                  || disableDoneButton || (levelClips.sim_scripts[activeClip].character.type === "agent" && !levelClips.sim_scripts[activeClip].agent_recorded_audio && agentAudioPlayer.current === null) ?
                  classes.nextButton : classes.nextButtonWithAnimation}
                disabled={disableDoneButton}
                onClick={() => handleDoneButton(simId, level, config)}
              >
                {disableDoneButton ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  "Done"
                )}
              </Button>
            ) : userResponse &&
              userResponse.mode === "practice" &&
              !isRecording ? (
              <Button
                variant="contained"
                disableElevation
                className={(isAudioPlaying && levelClips && levelClips.sim_scripts && levelClips.sim_scripts[activeClip])
                  || disableNextButton || (levelClips.sim_scripts[activeClip].character.type === "agent" && agentAudioPlayer.current === null) ?
                  classes.nextButton : classes.nextButtonWithAnimation}
                onClick={handleNextChange}
                disabled={disableNextButton}
              >
                <ArrowForwardIosIcon className={classes.arrowForward} />
              </Button>
            ) : (
              ""
            )}
          </div>
          {levelClips.sim_scripts[activeClip].character.type === "agent" &&
            !levelClips.sim_scripts[activeClip].agent_recorded_audio &&
            (levelClips.sim_scripts[activeClip].audio_url ||
              (levelClips.sim_scripts[activeClip].agent_recorded_url &&
                continueSimLevel)) &&
            userResponse.mode === "practice" &&
            !isRecording && (
              <div className={classes.centerElement}>
                <audio
                  key={
                    continueSimLevel &&
                      levelClips.sim_scripts[activeClip].agent_recorded_url
                      ? levelClips.sim_scripts[activeClip].agent_recorded_url
                      : levelClips.sim_scripts[activeClip].audio_url
                  }
                  ref={agentAudioPlayer}
                >
                  <source
                    src={
                      continueSimLevel &&
                        levelClips.sim_scripts[activeClip].agent_recorded_url
                        ? levelClips.sim_scripts[activeClip].agent_recorded_url
                        : levelClips.sim_scripts[activeClip].audio_url
                    }
                    type="audio/mp3"
                  />
                </audio>
                {isPlaying ? (
                  <IconButton
                    color="primary"
                    aria-label="pause"
                    component="span"
                    size="medium"
                    onClick={pauseRecordedAudio}
                  >
                    <PauseCircleFilledIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="play"
                    component="span"
                    size="medium"
                    onClick={playRecordedAudio}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton>
                )}
                <span>Play recorded clip</span>
              </div>
            )}
          <div className={classes.centerElement} style={{ display: record ? 'flex' : 'none' }}>
            <ReactMic
              record={record}
              className="sound-wave"
              width={150}
              strokeColor="#f27e7f"
              backgroundColor="#fcfcfc"
            />
          </div>
{/*
          <Button onClick={simulateAudioUpload}>
              simulate answer
            </Button>
*/}
          <RealTimeFeedbackDialog
            open={open}
            activeClip={activeClip}
            handleAutoPlayRecordStart={handleAutoPlayRecordStart}
            loader={loader}
            disableNextButton={disableNextButton}
            continueSimLevel={continueSimLevel}
            agentAudioPlayer={agentAudioPlayer}
            isPlaying={isPlaying}
            playRecordedAudio={playRecordedAudio}
            setOpen={setOpen}
            intervalRef={intervalRef}
            pauseRecordedAudio={pauseRecordedAudio}
            handleViewState={handleViewState}
            setStart={setStart}
            recPer={recPer}
            stopRecording={stopRecording}
            setActiveClip={setActiveClip}
            level={level}
            disableDoneButton={disableDoneButton}
          />
          <div className={classes.restartDiv}>
            <Button
              variant="contained"
              disableElevation
              className={classes.restartButton}
              startIcon={
                <img
                  src={RestartIcon}
                  alt="restart"
                  className={classes.restartIcon}
                />
              }
              onClick={handleRestart}
            >
              Restart
            </Button>
            <Button
              variant="contained"
              disableElevation
              className={classes.exitButton}
              startIcon={<CloseIcon className={classes.exitIcon} />}
              style={{
                backgroundColor: "#FFE7E7",
                color: "#99455F",
                minWidth: "2vw",
                maxWidth: "5vw",
              }}
              onClick={handleExitButton}
            >
              Exit
            </Button>
          </div>
        </div>
      </Paper>
      <Divider className={classes.divider} />
        <div className={classes.footerDiv}>
          <Grid container className={classes.footerContainer}>
            <Grid item xs={6}>
              {simTitle}
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
              Practice Mode :
              {userResponse.mode === "practice" ? (
                <span className={classes.practiceSpan}> ON</span>
              ) : (
                <span className={classes.practiceSpan}> OFF</span>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  return (
    <div>
      <CircularProgress color="secondary" />
    </div>
  );
};

SimWizard.propTypes = {
  fetchLevelClips: PropTypes.func,
  levelClips: PropTypes.object,
  levels: PropTypes.array,
  simId: PropTypes.string,
  updateStats: PropTypes.func,
  handleViewState: PropTypes.func,
  addAudioToClip: PropTypes.func,
  userResponse: PropTypes.object,
  reset: PropTypes.func,
  addScreenRecorderBlob: PropTypes.func,
  simTitle: PropTypes.string,
  updateCurrentClip: PropTypes.func,
  restartLevelInTakeSim: PropTypes.func,
  continueSimLevel: PropTypes.bool,
  uploadRecordedVideo: PropTypes.func,
  addAssignmentStatus: PropTypes.func,
  logError: PropTypes.func,
  saveVideoBlob: PropTypes.func,
  captivateWindowRef: PropTypes.object,
  simProductType: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  levelClips: state.sim.levelClips,
  levelClipsTemp: state.sim.levelClipsTemp,
  levels: state.sim.stats.result.levels,
  simId: state.sim.stats.result.sim_id,
  userResponse: state.sim.userResponse,
  simTitle: state.sim.simTitle,
  elapsedTime: state.sim.elapsedTime,
  totalSeconds: state.sim.totalSeconds,
  caseValuesArray: state.sim.caseValuesArray,
  captivateWindowRef: state.sim.captivateWindowRef,
  simProductType: state.sim.currentSimProductType,
});

const mapDispatchToProps = {
  fetchLevelClips,
  updateStats,
  addAudioToClip,
  reset,
  addScreenRecorderBlob,
  updateCurrentClip,
  restartLevelInTakeSim,
  uploadRecordedVideo,
  userFinalScoreResponse: userFinalScoreResponse,
  addAssignmentStatus,
  logError,
  saveVideoBlob,
};
export default connect(mapStateToProps, mapDispatchToProps)(SimWizard);

