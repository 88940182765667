/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Button,
} from '@material-ui/core';
import { updateStats, reset, addAudioToClip } from '../../../redux/sims/actions';
import { simWizard } from './SimWizard';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const RealTimeFeedbackDialog = ({
  open,
  levelClips,
  activeClip,
  loader,
  continueSimLevel,
  agentAudioPlayer,
  isPlaying,
  handleAutoPlayRecordStart,
  pauseRecordedAudio,
  playRecordedAudio,
  setOpen,
  intervalRef,
  setActiveClip,
  updateStats,
  userResponse,
  handleViewState,
  reset,
  setStart,
  disableNextButton,
  stopRecording,
  recPer,
  simId,
  disableDoneButton,
  level,
  addAudioToClip,
}) => {
  const useStyles = makeStyles((theme) => simWizard(theme));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let loading = !(
        /* levelClips.sim_scripts[activeClip].tests &&
        levelClips.sim_scripts[activeClip].keywords &&
        levelClips.sim_scripts[activeClip].missedKeywords &&
        levelClips.sim_scripts[activeClip].correctKeywords &&
        (
            (levelClips.sim_scripts[activeClip].missedKeywords.length > 0) ||
            (levelClips.sim_scripts[activeClip].correctKeywords.length > 0)
        ) */
        levelClips.sim_scripts[activeClip].grade_score || (levelClips.sim_scripts[activeClip].grade_score === 0)
    );
    if (levelClips.sim_scripts[activeClip].natural_lenguage) {
      setIsLoading(loading);  
    } else {
      setIsLoading(false);
    }

  }, [levelClips.sim_scripts, activeClip]);

  // when real time feedback is enabled then handle next button
  const handleFeedbackNextChange = () => {
    setOpen(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    if (activeClip === levelClips.sim_scripts.length - 1) {
      updateStats(
        simId,
        level,
        'started',
        userResponse.mode,
        activeClip,
        true,
        true,
      );
      reset();
    } else {
      updateStats(
        simId,
        level,
        'started',
        userResponse.mode,
        activeClip + 1,
        true,
        true,
      );
      setActiveClip(activeClip + 1);
      if (
        userResponse.mode === 'autoplay'
        && !levelClips.sim_scripts[activeClip + 1].agent_recorded_audio
        && levelClips.sim_scripts[activeClip + 1].character.type === 'agent'
      ) {
        handleAutoPlayRecordStart(activeClip + 1, true);
      }
    }
  };
  // Closing real time feedback option
  const handleClose = () => {
    const newSimScripts = [];
    let updatedScript = {};
    for (let i = 0; i < levelClips.sim_scripts.length; i += 1) {
      if (i === activeClip) {
        updatedScript = levelClips.sim_scripts[activeClip];
        updatedScript.correctKeywords = [];
        updatedScript.missedKeywords = [];
        updatedScript.audio_url = '';
        updatedScript.agent_recorded_audio = '';
        updatedScript.recorded = false;
        newSimScripts.push(updatedScript);
      } else {
        newSimScripts.push(levelClips.sim_scripts[i]);
      }
    }
    const newLevelClip = { ...levelClips, sim_scripts: newSimScripts };
    addAudioToClip(newLevelClip);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    setOpen(false);
    if (userResponse.mode === 'autoplay') {
      handleAutoPlayRecordStart(activeClip, true);
    }
  };

  const handleFeedbackDoneButton = async (simId, level) => {
    setOpen(false);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    updateStats(
      simId,
      level,
      'completed',
      userResponse.mode,
      activeClip,
      false,
    );

    //await sendAudioToSnowfly();

    handleViewState(true);
    reset();
    setStart(false);
    if (recPer === true) {
      stopRecording();
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
      scroll="paper"
    >
      <DialogTitle className={classes.label} id="form-dialog-title">
        Real Time Feedback <br />
        {
          (levelClips.sim_scripts[activeClip].natural_lenguage ) ? 
          (
            (!isLoading ) ? 
              (
                (levelClips.sim_scripts[activeClip].grade_score >= (levelClips.sim_scripts[activeClip].overalConfidence ? levelClips.sim_scripts[activeClip].overalConfidence : 60)) ?
                    <CheckIcon className={classes.checkIcon} style={{color: 'green', fontSize: '4rem'}}/> :
                    <ClearIcon className={classes.crossIcon} color="error" style={{fontSize: '4rem'}}/>
              ) : 
                <div className={classes.loader}>
                  <CircularProgress color="primary"/>
                </div>
          ) :
            ''
        }
      </DialogTitle>
      <hr className={classes.hr}/>
      <DialogContent className={classes.modalContent}>
      <Grid container spacing={2}>
          {
            (
                (levelClips.sim_scripts[activeClip].keywords && levelClips.sim_scripts[activeClip].keywords.length > 0 && !levelClips.sim_scripts[activeClip].natural_lenguage) ||
                (levelClips.sim_scripts[activeClip].tests && levelClips.sim_scripts[activeClip].tests.length > 0 && levelClips.sim_scripts[activeClip].natural_lenguage)
            ) ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            {(!levelClips.sim_scripts[activeClip].natural_lenguage) ? (
              <Grid item xs={12} className={classes.grid}>
                <Grid item xs={6}>
                  <InputLabel className={classes.missedKeywords}>
                    Missed Keywords
                  </InputLabel>
                  {levelClips
                  && levelClips.sim_scripts[activeClip].missedKeywords
                  && levelClips.sim_scripts[activeClip].missedKeywords.map(
                    (missed) => (
                      <li className={classes.list} key={missed.name}>
                        { missed.name }
                      </li>
                    ),
                  )}
                </Grid>
                <Grid item xs={6} className={classes.labelGrid}>
                  <InputLabel className={classes.correctKeywords}>
                    Correct Keywords
                  </InputLabel>
                  {levelClips
                  && levelClips.sim_scripts[activeClip].correctKeywords
                  && levelClips.sim_scripts[activeClip].correctKeywords.map(
                    (correct) => {
                      return <li className={classes.list} key={correct.name}>
                        {correct.name}
                      </li>
                    },
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.grid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <InputLabel style={{color: 'black', marginBottom: '5%'}}>
                  Tests
                </InputLabel>
                <ul style={{width: '70%', margin: '0', padding: '0'}}>
                {levelClips
                && levelClips.sim_scripts[activeClip].correctKeywords
                && levelClips.sim_scripts[activeClip].correctKeywords.map(
                  (correct) => (
                    <li className={classes.list} key={correct.name} style={{display: 'flex', justifyContent: 'space-between', borderTop: '1px solid black', paddingLeft: '5%', paddingRight: '5%'}}>
                      { levelClips.sim_scripts[activeClip].keywords.length > 0 ? correct.name : correct.name }
                      <CheckIcon className={classes.checkIcon} style={{color: 'green'}} />
                    </li>
                  ),
                )}
                {levelClips
                && levelClips.sim_scripts[activeClip].missedKeywords
                && levelClips.sim_scripts[activeClip].missedKeywords.map(
                  (missed) => (
                    <li className={classes.list} key={missed.name} style={{display: 'flex', justifyContent: 'space-between' ,borderTop: '1px solid black', paddingLeft: '5%', paddingRight: '5%'}}>
                      { levelClips.sim_scripts[activeClip].keywords.length > 0 ? missed.name : missed.name }
                      <ClearIcon className={classes.crossIcon} color="error" />
                    </li>
                  ),
                )}
                </ul>
                {isLoading && (
                  <div className={classes.loader}>
                    <CircularProgress color="primary" />
                  </div>
                )}
              </Grid>
            )}
            </div>
          ) : (
            <Grid>
              <h2>No {(!levelClips.sim_scripts[activeClip].natural_lenguage) ? 'keywords' : 'tests'} are present</h2>
              <p>
                To try again, please use the Retry button. Otherwise,
                select the Next button to continue with assignment.
              </p>
            </Grid>
          )}
          {loader
          && levelClips.sim_scripts[activeClip].keywords.length > 0 ? (
            <div className={classes.loader}>
              <CircularProgress color="primary" />
            </div>
            ) : null}
          <hr className={classes.hr} />
      </Grid>
          {levelClips.sim_scripts[activeClip].character.type
          === 'agent'
          && (levelClips.sim_scripts[activeClip].audio_url
            || (levelClips.sim_scripts[activeClip].agent_recorded_url
              && continueSimLevel))
          && !levelClips.sim_scripts[activeClip].agent_recorded_audio && (
        <Grid container spacing={2}>
            <div className={classes.player}>
              <audio
                key={levelClips.sim_scripts[activeClip].audio_url}
                ref={agentAudioPlayer}
              >
                <source
                  src={levelClips.sim_scripts[activeClip].audio_url}
                  type="audio/mp3"
                />
              </audio>
              {isPlaying ? (
                <IconButton
                  color="primary"
                  aria-label="pause"
                  component="span"
                  size="medium"
                  onClick={pauseRecordedAudio}
                >
                  <PauseCircleFilledIcon />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.playerPlayBtn}
                  aria-label="play"
                  component="span"
                  size="medium"
                  onClick={playRecordedAudio}
                >
                  <PlayCircleFilledIcon />
                </IconButton>
              )}
              <span>Play recorded clip</span>
            </div>
          {
            ((process.env.REACT_APP_STAGE === 'local') && (!loader && levelClips.sim_scripts[activeClip].transcription)) && (
                <Tooltip placement="top"
                  title={levelClips.sim_scripts[activeClip].transcription}
                   style={{textAlign: 'center', margin: '0 auto'}}
                >
                  <div>
                      <Typography variant="button" align="center">
                        <strong>Transcript</strong>
                      </Typography>
                  </div>
                </Tooltip>
          )}
      </Grid>
      )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Retry
        </Button>
        {activeClip === levelClips.sim_scripts.length - 1 ? (
          <Button
            onClick={() => handleFeedbackDoneButton(simId, level)}
            color="primary"
            variant="outlined"
            disabled={disableDoneButton}
          >
            {disableDoneButton ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              'Done'
            )}
          </Button>
        ) : (
          <Button
            disabled={disableNextButton}
            onClick={handleFeedbackNextChange}
            color="primary"
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
RealTimeFeedbackDialog.propTypes = {
  updateStats: PropTypes.func,
  reset: PropTypes.func,
  addAudioToClip: PropTypes.func,
  open: PropTypes.bool,
  activeClip: PropTypes.number,
  loader: PropTypes.bool,
  levelClips: PropTypes.object,
  continueSimLevel: PropTypes.bool,
  agentAudioPlayer: PropTypes.object,
  isPlaying: PropTypes.bool,
  handleAutoPlayRecordStart: PropTypes.func,
  pauseRecordedAudio: PropTypes.func,
  playRecordedAudio: PropTypes.func,
  setOpen: PropTypes.func,
  intervalRef: PropTypes.object,
  setActiveClip: PropTypes.func,
  userResponse: PropTypes.object,
  handleViewState: PropTypes.func,
  setStart: PropTypes.func,
  disableNextButton: PropTypes.bool,
  simId: PropTypes.string,
  stopRecording: PropTypes.func,
  recPer: PropTypes.bool,
  disableDoneButton: PropTypes.bool,
  level: PropTypes.string,
};
const mapStateToProps = (state) => ({
  levelClips: state.sim.levelClips,
  userResponse: state.sim.userResponse,
  simId: state.sim.stats.result.sim_id,
});
const mapDispatchToProps = {
  updateStats,
  reset,
  addAudioToClip,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeFeedbackDialog);
