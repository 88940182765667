import React from 'react';
import PropTypes from 'prop-types';
import {
    Divider,
    Typography,
    Button,
    Select,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useCharacterDataStyle } from './CharacterData';

const GenerateAudio = ({
   data,
   selectedCharacter,
   setSwitchGenerate,
   switchGenerate,
   handleAgentRecordedAudio,
   index,
   updateData,
   handleAudioChange,
   genAudio,
   setVoice,
   currentVoice,
   setGenAudio,
   node,
   voice,
   newSim,
   showPlayAudio,
   audioPlayer,
}) => {
    const classes = useCharacterDataStyle();

    const setAgentRecordedAudio = (record) => {
        // eslint-disable-next-line no-param-reassign
        data.agent_recorded_audio = record;
        setSwitchGenerate(!switchGenerate);
        handleAgentRecordedAudio(index);
        updateData(index, data);
    };

    const switchGenerateAudio = () => {
        handleAudioChange(index);
        updateData(index, data);
        setSwitchGenerate(!switchGenerate);
    };

    const generate = () => {
        setVoice(currentVoice[0]);
        setGenAudio(true);
    };

    const handleVoiceChange = (event) => {
        setVoice(event.target.value);
    };

    const showGenerateAudio = (switchAudio) => {
        if (
            selectedCharacter.type === 'agent'
            && data.audio_url
            && !data.agent_recorded_audio
        ) {
            return (
                <div className={classes.generateDiv}>
                    <Button className={classes.switchBtn} onClick={() => setAgentRecordedAudio(false)}>
                        <Typography className={classes.promptRecord}>
                            Prompt to record:&nbsp;
                        </Typography>
                        SWITCH
                    </Button>
                </div>
            );
        }
        if (selectedCharacter.type === 'agent' && data.agent_recorded_audio) {
            return (
                <div className={classes.generateDiv}>
                    <Button
                        className={classes.recordSwitchBtn}
                        onClick={() => switchGenerateAudio(true)}
                    >
                        <Typography className={classes.recordedAudio}>
                            Recorded Audio:
                        </Typography>
                        <span className={classes.switch}>SWITCH</span>
                    </Button>
                    <Divider />
                    {
                        !genAudio ? (
                                <Button className={classes.generateAudioBtn} onClick={generate}>
                                    GENERATE AUDIO
                                </Button>
                            )
                            : (
                                <div className={classes.selectDiv} ref={node}>
                                    <Select
                                        variant="outlined"
                                        name="voice"
                                        size="small"
                                        className={classes.select}
                                        value={voice}
                                        onChange={handleVoiceChange}
                                        native
                                    >
                                        {Object.entries(newSim.selectVoices).map(([key, value]) => (
                                            <option value={key} key={key} className={classes.descriptionLabel}>
                                                {value}
                                            </option>
                                        ))}
                                    </Select>
                                    <CheckBoxIcon className={classes.chckboxIcn} onClick={showPlayAudio} />
                                </div>
                            )
                    }
                    <audio key={data.audio_url} ref={audioPlayer}>
                        <source src={data.audio_url} type="audio/mp3" />
                    </audio>
                </div>
            );
        }
        if (selectedCharacter.type === 'agent' && switchAudio) {
            return (
                <div className={classes.generateDiv}>
                    <Button className={classes.switchBtn} onClick={() => setAgentRecordedAudio(false)}>
                        <Typography className={classes.promptRecord}>
                            Prompt to record:&nbsp;
                        </Typography>
                        SWITCH
                    </Button>
                </div>
            );
        }
        if (selectedCharacter.type === 'agent' && !switchAudio) {
            return (
                <div className={classes.generateDiv}>
                    <Button
                        className={classes.recordSwitchBtn}
                        onClick={() => switchGenerateAudio(true)}
                    >
                        <Typography className={classes.recordedAudio}>
                            Recorded Audio:
                        </Typography>
                        <span className={classes.switch}>SWITCH</span>
                    </Button>
                    <Divider />
                    <Button className={classes.generateAudio} onClick={showPlayAudio}>
                        GENERATE AUDIO
                    </Button>
                    <audio key={data.audio_url} ref={audioPlayer}>
                        <source src={data.audio_url} type="audio/mp3" />
                    </audio>
                </div>
            );
        }
        if (!selectedCharacter.type === 'agent' && !switchAudio) {
            return (
                <div className={classes.generateDiv}>
                    <Button className={classes.generateAudioBtn} onClick={showPlayAudio}>
                        GENERATE AUDIO
                    </Button>
                    <audio key={data.audio_url} ref={audioPlayer}>
                        <source src={data.audio_url} type="audio/mp3" />
                    </audio>
                </div>
            );
        }
        return (
            <div className={classes.generateDiv}>
                {
                    !genAudio ? (
                            <Button className={classes.generateAudioBtn} onClick={generate}>
                                GENERATE AUDIO
                            </Button>
                        )
                        : (
                            <div className={classes.selectDiv} ref={node}>
                                <Select
                                    variant="outlined"
                                    name="voice"
                                    size="small"
                                    className={classes.select}
                                    value={voice}
                                    onChange={handleVoiceChange}
                                    native
                                >
                                    {Object.entries(newSim.selectVoices).map(([key, value]) => (
                                        <option value={key} key={key} className={classes.descriptionLabel}>
                                            {value}
                                        </option>
                                    ))}
                                </Select>
                                <CheckBoxIcon className={classes.chckboxIcn} onClick={showPlayAudio} />
                            </div>
                        )
                }
                <audio key={data.audio_url} ref={audioPlayer}>
                    <source src={data.audio_url} type="audio/mp3" />
                </audio>
            </div>
        );
    };

    return (
        <Typography component="span" style={{ marginLeft: 'auto' }}>
            { showGenerateAudio(!switchGenerate)}
        </Typography>
    );
};

GenerateAudio.propTypes = {
    index: PropTypes.number,
    data: PropTypes.object,
    selectedCharacter: PropTypes.object,
    setSwitchGenerate: PropTypes.func,
    switchGenerate: PropTypes.bool,
    handleAgentRecordedAudio: PropTypes.func,
    updateData: PropTypes.func,
    handleAudioChange: PropTypes.func,
    genAudio: PropTypes.bool,
    setVoice: PropTypes.func,
    currentVoice: PropTypes.any,
    setGenAudio: PropTypes.func,
    node: PropTypes.object,
    voice: PropTypes.string,
    newSim: PropTypes.object,
    showPlayAudio: PropTypes.func,
    audioPlayer: PropTypes.object,
};
export default GenerateAudio;
