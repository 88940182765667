import axios from 'axios';
import config from '../config';
import sims from '../permissions/sims';
import admin from '../permissions/admin';
import dashboard from '../permissions/dashboard';

const getHeader = (task, type) => ({
    headers: {
        Authorization: config.getToken(),
        task,
        type,
    },
});

export const addLevelScript = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/scripts`, data, getHeader(null, null))
    .then((res) => res.data);

export const saveKeywords = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/keywords`,
    data,
    getHeader(null, null),
);

export const saveAvatarDetails = async (data) => axios
    .patch(
        `${config.API_BASE_URL}/api/v1/sims/avatars/update-details`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

export const editLevels = async (id, data) => axios.patch(
    `${config.API_BASE_URL}/api/v1/sims/editLevel/${id}`,
    data,
    getHeader(null, null),
);

export const uploadAudio = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/grade-transcription`,
    data,
    getHeader(null, null),
);

export const deleteScript = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/delete-script`,
    data,
    getHeader(null, null),
);

export const generateAudio = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/generate-voice`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

export const getSims = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/list?product_id=${id}`, getHeader(null, null));

export const getSimById = async (id) => axios.get(
    `${config.API_BASE_URL}/api/v1/sims/details?simId=${id}`,
    getHeader(null, null),
);

export const deleteLevel = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/delete-level`,
    data,
    getHeader(null, null),
);

export const deleteSim = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/delete`,
    data,
    getHeader(sims.sim_list.delete.task, sims.sim_list.delete.type),
);

export const duplicateSim = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/duplicate-sim`, data, getHeader(null, null));

export const getMissedCorrectKeywords = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/get-missed-correct-keywords?id=${id}`, getHeader(null, null));

export const deleteRole = async (id) => axios.post(
    `${config.API_BASE_URL}/api/v1/company/delete-role`,
    id,
    getHeader(admin.roles.delete.task, admin.roles.delete.type),
);

export const deleteGroup = async (id) => axios.post(
    `${config.API_BASE_URL}/api/v1/groups/delete`,
    id,
    getHeader(admin.groups.delete.task, admin.groups.delete.type),
);

export const uploadRecordedAudio = async (data) => axios.post(`${config.API_BASE_URL}/api/v1/sims/upload-audio`, data, getHeader(null, null));

export const fetchUserByGroup = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/users/user-by-group?group_id=${id}`, getHeader(null, null));
export const SaveAssessorNotes = async (id, notes, forUpdate) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/save-assessor-notes/${id}`,
        notes,
        getHeader(
            forUpdate
                ? dashboard.assessor_notes.update.task
                : dashboard.assessor_notes.create.task,
            forUpdate
                ? dashboard.assessor_notes.update.type
                : dashboard.assessor_notes.create.type,
        ),
    )
    .then((res) => res.data);

export const checkUserResultExist = async (payload, user_id) => axios({
    url: `${config.API_BASE_URL}/api/v1/sims/result?check_result_exist=true${user_id ? `&user_id=${user_id}` : ''
    }`,
    method: 'POST',
    ...getHeader(null, null),
    data: payload,
})
    .then((res) => res.data.result_exist)
    .catch(() => false);
export const fetchAllAssignments = async (myAssignments, tab, textSearch, task, type) => axios.get(`${config.API_BASE_URL}/api/v1/sims/excel/assignments?${textSearch ? `&status=${textSearch}` : ''}&my_assignment=${myAssignments}${tab ? `&tab=${tab}` : ''}`,
    {
        ...getHeader(task, type),
        responseType: 'arraybuffer',
    });

export const fetchScoreLists = async (textSearch, finalResult, download, filterDatas) => {
    return axios.post(
        `${config.API_BASE_URL}/api/v1/sims/level/listing?${textSearch ? `&status=${textSearch}` : ''}${finalResult ? `&final_result=${finalResult}` : ''}${download ? `&download=${download}` : ''}`,
        filterDatas,
        {
            ...getHeader(null, null),
            responseType: 'arraybuffer',
        }
    );
}

export const downloadTemplate = async () => axios.get(`${config.API_BASE_URL}/api/v1/sims/download-template`, {
    ...getHeader(null, null),
    responseType: 'arraybuffer',
});
export const duplicateEmailCheck = async (email, id) => axios.get(
    `${config.API_BASE_URL}/api/v1/users/duplicate-email?emailId=${email}${id ? `&id=${id}` : ''
    }`,
    getHeader(null, null),
);

export const savePermission = async (data, header) => axios.post(
    `${config.API_BASE_URL}/api/v1/roles/save-permissions`,
    data,
    getHeader(header.task, header.type),
);

export const getAwsPresignedUrl = async () => axios.get(
    `${config.API_BASE_URL}/api/v1/presigned/url`,
    getHeader(null, null),
);

export const uploadRecordedVideo = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/upload-video`,
    data,
    getHeader(null, null),
);
export const AssignTemporaryRole = async (data, user) => axios.post(
    `${config.API_BASE_URL}/api/v1/roles/add-temp-role?user_id=${user}`, data,
    getHeader(admin.roles.update.task, admin.roles.update.type),
);
export const DeleteTempRole = async (role, user) => axios.post(
    `${config.API_BASE_URL}/api/v1/roles/delete-temp-role?user_id=${user}`, { role },
    getHeader(null, null),
);
export const fetchVidoeDetails = async (id) => axios.get(
    `${config.API_BASE_URL}/api/v1/sims/get-video?id=${id}`,
    getHeader(null, null),
);

export const downloadExcelLevel = async (levelId) => axios.get(`${config.API_BASE_URL}/api/v1/sims/excel/levels?level_id=${levelId}`,
    {
        ...getHeader(),
        responseType: 'arraybuffer',
    });

export const checkContinueLevel = async (simId, simLevelId, mode) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/continue`,
    { simId, simLevelId, mode },
    getHeader(null, null),
);

export const getUserScriptId = async (user_response_id, sim_script_id) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/user-script-id`,
    { user_response_id, sim_script_id },
    getHeader(null, null),
);

// forgot Password
export const forgotPassword = async (payload) => {
    axios.post(
        `${config.API_BASE_URL}/api/v1/login/forgot-password`,
        payload,
        getHeader(null, null),
    );
};
export const resetPassword = async (payload) => {
    axios.post(
        `${config.API_BASE_URL}/api/v1/login/reset-password`,
        payload,
        getHeader(null, null),
    );
};
export const removePdf = async (simId, documentId) => axios.patch(
    `${config.API_BASE_URL}/api/v1/sims/remove-document`,
    { simId, documentId },
    getHeader(null, null),
);

export const realTimeFeedback = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/scripts/real-time-feedback`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

/**
 * Updates the SendToSnowfly flag for the passed SIM levels
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendToSnowfly = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/send-to-snowfly`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

/**
 * Updates the toggleScreenRecordingSwitch flag for the passed SIM levels
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const toggleScreenRecordingSwitch = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/toggle-screen-recording-switch`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

export const DeleteUserEmployee = async (id) => axios.post(
    `${config.API_BASE_URL}/api/v1/employee/delete`,
    { id },
    getHeader(admin.employee_list.delete.task, admin.employee_list.delete.type),
);
export const getSimsData = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/simData?product_id=${id}`, getHeader(null, null));

export const updateHintText = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/scripts/hint-text`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);
export const updateKeywordScore = async (data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/levels/keyword-score`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);

export const deleteExitedLevelResult = async (id, data = {}) => axios.patch(
    `${config.API_BASE_URL}/api/v1/sims/update-exited-level-result/${id}`,
    data,
    getHeader(null, null),
);
export const getAssignmentHistory = async (id) => axios.get(`${config.API_BASE_URL}/api/v1/sims/agent-assignment-history?assignment_id=${id}`, getHeader(null, null));
export const SaveNotification = async (state) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/users/save-notification`,
        state,
        getHeader(null, null),
    )
    .then((res) => res.data);
export const downloadExcelNotes = async () => axios.get(`${config.API_BASE_URL}/api/v1/users/excel/download-notes`,
    {
        ...getHeader(),
        responseType: 'arraybuffer',
    });
export const recoverSim = async (id) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/recover?sim_id=${id}`, {},
    getHeader(null, null),
);
export const addCaptivate = async (formData, sim_id) => axios.post(`${config.API_BASE_URL}/api/v1/sims/add-captivate/${sim_id}`,
    formData,
    getHeader(null, null))
    .then((res) => res.data);
export const deleteAll = async () => axios.post(`${config.API_BASE_URL}/api/v1/sims/trash`, {},
    getHeader(null, null));
export const addTutorial = async (formData, sim_id) => axios.post(`${config.API_BASE_URL}/api/v1/sims/add-tutorial-video/${sim_id}`,
    formData,
    getHeader(null, null))
    .then((res) => res.data);
export const addCaptivateTutorial = async (formData, sim_id) => axios.post(`${config.API_BASE_URL}/api/v1/sims/add-captivate-video/${sim_id}`,
    formData,
    getHeader(null, null))
    .then((res) => res.data);
export const removeUploads = async (simId, filedType) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/remove-media?sim_id=${simId}&field_type=${filedType}`,
    {},
    getHeader(null, null),
);
export const uploadStatus = (fileId, size) => axios.get(`${config.API_BASE_URL}/api/v1/sims/upload-video-status`, {
    headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
        'x-file-name': fileId,
        'file-size': size,
    },
});

export const uploadComplete = (fileId, id) => axios.post(`${config.API_BASE_URL}/api/v1/sims/upload-video-complete`, {
    userResponseId: id,
}, {
    headers: {
        Authorization: config.getToken(),
        task: null,
        type: null,
        'x-file-name': fileId,
    },
});
export const uploadFiles = (chunk, fileId, startChunk, endChunk, fileSize) => axios.post(`${config.API_BASE_URL}/api/v1/sims/upload-video-files`,
    chunk,
    {
        headers: {
            Authorization: config.getToken(),
            task: null,
            type: null,
            'x-file-name': fileId,
            'Content-Range': `bytes ${startChunk}-${endChunk}/${fileSize}`,
            'file-size': fileSize,
        },
    });
export const audioMerge = async (data) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/snowfly-audio-merge`, data,
    getHeader(null, null),
);
export const removeSimPassFail = async (sim_level_id, sim_id) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/remove-sim-passFail?sim_level_id=${sim_level_id}&sim_id=${sim_id}`, {},
    getHeader(null, null),
);
export const copyHintTextFromLevelOne = async (sim_id, level_id, import_to_levelId) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/copy-hint-text?sim_id=${sim_id}&level_id=${level_id}&import_to_levelId=${import_to_levelId}`, {},
    getHeader(null, null),
);
export const saveTakesimInput = async (simId, userResponseId, data) => axios
    .post(
        `${config.API_BASE_URL}/api/v1/sims/save-take-sim-input-details?simId=${simId}&userResponseId=${userResponseId}`,
        data,
        getHeader(null, null),
    )
    .then((res) => res.data);
export const uploadKeywordExcel = async (data, type, simId) => axios.post(
    `${config.API_BASE_URL}/api/v1/sims/upload-keyword/${type}?sim_id=${simId}`, data,
    getHeader(null, null),
);
const chunkedUpload = async (url, file) => {
    if (!file) {
        throw "Please select a file to upload.";
    }

    const headers = {
        "Authorization": config.getToken(),
    };
    const chunkSize = 512 * 1024; // 512KB (adjust based on your requirements)
    const totalChunks = Math.ceil(file.size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = start+chunkSize;
    let fileSize = file.size;
    let error;

    const uploadNextChunk = async () => {
        return new Promise(async (resolve, reject) => {
            let response;

            if (end <= file.size) {
                const chunk = file.slice(start, end);
                const formData = new FormData();
                formData.append("chunkData", chunk);
                formData.append("chunkNumber", chunkNumber);
                formData.append("totalChunks", totalChunks);
                formData.append("originalname", file.name);

                try {
                    response = await axios.post(url,
                        formData,
                        getHeader(null, null)
                    );
                    if (!response) {
                        error = 'Upload Failed';
                        reject(error);
                        return;
                    }

                    response = response.data;
                    if (!response.success) {
                        error = 'Upload Failed';
                        reject(error);
                        return;
                    }

                    console.log('Chunked Upload Response = %o', {response});
                    console.log(`Chunk ${chunkNumber + 1}/${totalChunks} uploaded successfully`);

                    chunkNumber++;
                    start = end;
                    end = start + chunkSize;
                    if (end > fileSize) {
                        end = fileSize;
                    }
                    if (chunkNumber < totalChunks && !response.complete) {
                        response = await uploadNextChunk();
                    }
                } catch (err) {
                    error = "Error uploading chunk:" + err.toString();
                    reject(err);
                    return;
                }
            }

            if (error) {
                reject(error);
            }

            resolve(response);
        });
    }

    return await uploadNextChunk();
}

export const uploadSIMTutorial = async (simId, downloadUrl, fileName) => {
    const url = `${config.API_BASE_URL}/api/v1/sims/add-tutorial-video/${simId}`
    let headers = getHeader(null, null);
    headers['content-type'] = 'application/json; charset=UTF-8';

    let result = await axios.post(
        url,
        {
            'url': downloadUrl,
            'fileName': fileName,
        },
        headers
    );
    return result.data ?? result;
}

export const uploadSIMAttachment = async (simId, downloadUrl, fileName, mimeType) => {
    const url = `${config.API_BASE_URL}/api/v1/sims/add-attachment`;

    let headers = getHeader(null, null);
    headers['content-type'] = 'application/json; charset=UTF-8';

    let result = await axios.post(
        url,
        {
            'id': simId ?? '',
            'url': downloadUrl,
            'fileName': fileName,
            'mimeType': mimeType
        },
        headers
    );

    return result.data ?? result;
}
export const getPresignedUrl = async (itemType, itemName) => {
    const url = `${config.API_BASE_URL}/api/v1/sims/get-presigned-url`;

    let headers = getHeader(null, null);
    headers['content-type'] = 'application/json; charset=UTF-8';

    let result =  await axios.post(
        url,
        {
            'type': itemType,
            'fileName': itemName,
        },
        headers
    );

    return result.data ?? result;
}

export const uploadToPresignedUrl = async (url, file) => {
    let data = new FormData();
    data.append('Key', file.name);
    data.append('Body', file);

    let result = await axios.put(
        url,
        file,
        {
            headers: {
                'Content-Type': '',
                'Accept': '*/*',
            }
        }
    );

    if (result.status === 200) {
        return { success: true };
    }

    return { success: false, status: result.status, statusText: result.statusText };
}